import Alert from 'react-bootstrap/Alert';

function AlertF() {
  return (
    <>
        {[
            'danger',
            ].map((variant) => (
            <Alert className='text-center' key={variant} variant={variant}>
                Website Under Development. Beta Version @ January 2025
            </Alert>
        ))}
    </>
  );
}

export default AlertF;