//AWS and REACT Libraries 

import { Authenticator } from '@aws-amplify/ui-react';

//page components
import NavMenu from '../section/LoggedInNav.js';
import SITELINKS from '../section/Footer.js';
import Banner from '../section/Title.js';
import MessagesData from '../section/MessagesData.js'//used to show the user when they are logged in how to use the website

//boostrap components 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const ProtectedPage = () => {
  return (

    <div className='App'>
      <Banner/>
      <NavMenu/> 
      <Authenticator className='login_container container' loginMechanisms={['email']} hideSignUp>

        <Container>
            <Row>
              <Col  xs={12} md={12} lg={12} xl={12}>
                <div className='leftContent text-center'>
                  <h2 className='UserNewsDashboard emphTitle padding'>MESSAGES</h2>
                  <hr></hr>
                  <h5 className='whiteFont emphTitle'>System Alerts</h5>
                  <br></br>
                  <div className='emphTitle'>
                    <p className='notice'>Scheduled Maintenance Notice: On 1/20/25, MIS will conduct a planned update on the Portal at 1PM EST for 3 Hours. 
                    The website will still be online"</p>  
                  </div>
                  <hr></hr>
                </div>              
              </Col>
              <Col  xs={12} md={12} lg={12} xl={12}>
                  <MessagesData/>
              </Col>
            </Row>

          </Container>
      </Authenticator>       

      <SITELINKS/> 
    
    </div>
  );
};